import { useEffect, useMemo, useRef, type FC } from "react";

import {
  Calendar20Filled,
  FolderAdd20Filled,
  FolderPerson20Filled,
  Form20Filled,
  LockClosedKey20Filled,
  PersonAdd20Filled,
  PersonCircle20Filled,
  Textbox20Filled,
  TextGrammarSettings20Filled,
  WrenchScrewdriver20Filled,
} from "@fluentui/react-icons";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getRouteApi } from "@tanstack/react-router";
import { throttle } from "lodash-es";
import {
  BotIcon,
  FileUpIcon,
  RouteIcon,
  TextQuoteIcon,
  Trash,
  UserRoundCogIcon,
  WebhookIcon,
} from "lucide-react";
import { useDateFormatter, type DateFormatterOptions } from "react-aria";
import { type SortDescriptor } from "react-aria-components";

import { cn, useId, type Awaitable } from "@dokworks/shared";
import {
  Cell,
  Column,
  Row,
  Spinner,
  Table,
  TableBody,
  TableHeader,
} from "@dokworks/ui";

import {
  logsQueryOptions,
  type AuditLog,
  type AuditResponseSubject,
  type LogListFilter,
} from "@/utils/fetch/log";

const routeApi = getRouteApi("/_auth/dossier/$dossierId");

export const DossiersLog: FC<{ className?: string }> = ({ className }) => {
  const filter = routeApi.useSearch({
    select: (search) =>
      search.logFilter ??
      ({ limit: 30, offset: 0, sortBy: "desc" } satisfies LogListFilter),
  });

  const {
    data,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isPending,
    isFetching,
  } = useInfiniteQuery(logsQueryOptions(filter));

  return (
    <>
      <div
        className={cn(
          "flex h-[840px] flex-1 items-center justify-center gap-2 px-6 py-2 pb-6",
          !isPending && "hidden",
          className,
        )}
      >
        <Spinner className="text-fg-accent" /> Loading...
      </div>
      <DossiersLogList
        items={data?.pages.flatMap((page) => page.results) ?? []}
        count={data?.pages[0].count ?? 0}
        filter={filter}
        className={cn(isPending ? "hidden" : undefined, className)}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onScrollEnd={() => {
          !isFetching && fetchNextPage();
        }}
        onScrollTop={() => {
          !isFetching && fetchPreviousPage();
        }}
      />
    </>
  );
};

interface DossiersLogListProps {
  items: AuditLog[];
  count: number;
  filter: LogListFilter;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  className?: string;

  onScrollTop: () => Awaitable<void>;
  onScrollEnd: () => Awaitable<void>;
}

const options: DateFormatterOptions = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const DossiersLogList: FC<DossiersLogListProps> = ({
  items,
  filter,
  className,
  hasNextPage,
  hasPreviousPage,
  onScrollEnd,
  onScrollTop,
}) => {
  const dateFormatter = useDateFormatter(options);
  const timestampId = useId();
  const navigate = routeApi.useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const sortDescriptor = useMemo<SortDescriptor>(
    () => ({
      column: timestampId,
      direction: filter?.sortBy === "asc" ? "ascending" : "descending",
    }),
    [filter?.sortBy, timestampId],
  );

  const handleScroll = useMemo(
    () =>
      throttle((ev: Event) => {
        const el = ev.target as HTMLDivElement;
        const bottom =
          Math.abs(el.scrollHeight - (el.scrollTop + el.clientHeight)) <= 1;

        (async () => {
          if (bottom && hasNextPage) {
            await onScrollEnd();
          } else if (el.scrollTop === 0 && hasPreviousPage) {
            const lastTop = el.querySelector("tbody")?.firstElementChild;

            await onScrollTop();
            if (lastTop && lastTop instanceof HTMLElement && ref.current) {
              ref.current.scrollTo({
                behavior: "instant",
                top: lastTop.getBoundingClientRect().top - 7,
              });
            }
          }
        })();
      }, 300),
    [hasNextPage, hasPreviousPage, onScrollEnd, onScrollTop],
  );

  useEffect(
    function applyOnScroll() {
      const { current } = ref;
      if (!current) return;

      const abort = new AbortController();
      const { signal } = abort;

      current?.addEventListener("scroll", handleScroll, {
        passive: true,
        signal,
      });

      return () => abort.abort();
    },
    [handleScroll],
  );

  return (
    <Table
      ref={ref}
      aria-label="Dossier audit log"
      className={cn("h-[840px]", className)}
      sortDescriptor={sortDescriptor}
      onSortChange={(descriptor) => {
        if (descriptor.column === timestampId) {
          navigate({
            replace: true,
            search: (search) => ({
              ...search,
              logFilter: {
                ...(search.logFilter ?? {
                  limit: 30,
                  offset: 0,
                }),
                sortBy: descriptor.direction === "ascending" ? "asc" : "desc",
              },
            }),
          });
        }
      }}
    >
      <TableHeader>
        <Column isRowHeader textValue="Audit">
          <span className="inline-flex items-center gap-2">
            <TextGrammarSettings20Filled className="text-fg-muted" />
            audit
          </span>
        </Column>
        <Column textValue="Descriptie">
          <span className="inline-flex items-center gap-2">
            <Textbox20Filled className="text-fg-muted" />
            descriptie
          </span>
        </Column>
        <Column textValue="Actie">
          <span className="inline-flex items-center gap-2">
            <WrenchScrewdriver20Filled className="text-fg-muted" />
            toepassing
          </span>
        </Column>
        <Column allowsSorting textValue="aangemaakt op" id={timestampId}>
          <span className="inline-flex items-center gap-2 truncate">
            <Calendar20Filled className="text-fg-muted" />
            aangemaakt op
          </span>
        </Column>
        <Column textValue="aangemaakt door">
          <span className="flex items-center gap-2">
            <PersonCircle20Filled />
            aangemaakt door
          </span>
        </Column>
      </TableHeader>
      <TableBody
        items={items}
        dependencies={[items, sortDescriptor, dateFormatter]}
      >
        {function renderRow(item) {
          const date = dateFormatter.format(new Date(item.timestamp));

          return (
            <Row id={item.id}>
              <Cell textValue={item.subject}>
                <p className="flex items-center gap-2">
                  <LogIcon subject={item.subject} action={item.action} />
                  {item.subject}
                </p>
              </Cell>
              <Cell>{prettyPrintSubject(item.subject, item.action)}</Cell>
              <Cell className="capitalize">{item.action.toLowerCase()}</Cell>
              <Cell className="capitalize">{date}</Cell>
              {/* TODO voeg hier een tooltip toe met de action_detail wanneer deze valid is */}
              <Cell
                className="capitalize"
                textValue={logInitiatorText(item.initiator, item.initiatorType)}
              >
                <p className="flex items-center gap-2">
                  <LogInitiatorIcon type={item.initiatorType} />
                  {logInitiatorText(item.initiator, item.initiatorType)}
                </p>
              </Cell>
            </Row>
          );
        }}
      </TableBody>
    </Table>
  );

  function prettyPrintSubject(
    subject: AuditResponseSubject,
    action: AuditLog["action"],
  ): string {
    const sentence: [subject: string, action: string] = ["", ""];

    switch (subject) {
      default:
        sentence[0] = subject;
        break;
    }

    switch (action) {
      case "Create": {
        sentence[1] = "aangemaakt";
        break;
      }

      case "Update": {
        sentence[1] = "aangepast";
        break;
      }

      case "Delete": {
        sentence[1] = "verwijderd";
        break;
      }

      default:
        break;
    }

    return sentence.join(" is ") + ".";
  }

  function logInitiatorText(
    initiator: AuditLog["initiator"],
    type: AuditLog["initiatorType"],
  ): string {
    const fallback = "Systeem";
    switch (type) {
      case "User":
        return initiator?.fullName ?? fallback;

      case "Webhook":
        return "Webhook";

      default:
        return fallback;
    }
  }
};

interface LogInitiatorIconProps {
  type: AuditLog["initiatorType"];
}

function LogInitiatorIcon({ type }: LogInitiatorIconProps) {
  switch (type) {
    case "User":
      return <PersonCircle20Filled className="text-fg-accent" />;

    case "Webhook":
      return (
        <span className="inline-flex size-5 items-center justify-center">
          <WebhookIcon className="size-4 text-fg-accent" />
        </span>
      );

    default:
      return (
        <span className="inline-flex size-5 items-center justify-center">
          <BotIcon className="size-4 text-fg-accent" />
        </span>
      );
  }
}

interface LogIconProps {
  subject: AuditResponseSubject;
  action: AuditLog["action"];
}

function LogIcon({ subject, action }: LogIconProps) {
  const className = "mb-0.5 size-5 text-fg-accent";
  switch (subject) {
    case "Dossier":
      switch (action) {
        case "Create":
          return (
            <FolderAdd20Filled className={cn(className, "text-fg-success")} />
          );
        case "Update":
          return <FolderPerson20Filled className={className} />;
        case "Delete":
          return <Trash className={cn(className, "text-fg-danger")} />;
        default:
          return <FolderPerson20Filled className={className} />;
      }
    case "User":
      switch (action) {
        case "Create":
          return (
            <PersonAdd20Filled className={cn(className, "text-fg-success")} />
          );

        default:
          return <LockClosedKey20Filled className={className} />;
      }

    case "UserProfile":
      return <UserRoundCogIcon className={className} />;

    case "Workflow":
      return <RouteIcon className={className} />;

    case "WorkflowFile":
      return <FileUpIcon className={className} />;

    case "Block":
      return <TextQuoteIcon className={className} />;

    default: {
      return <Form20Filled className="mb-0.5 text-fg-accent" />;
    }
  }
}
